import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {GET} from '../Database/Request'
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { LineChart, axisClasses } from '@mui/x-charts';
import Alert from '@mui/material/Alert';
const initInfo = {
    CycleCounter: 0,
    FirstAccessed: 0,
    LastAccessed: 0,
    Guid: "",
    IsApproved: 0,
    IsDeleted: 0,
    MAC: "",
    OnTime: 0,
    SiteId: 0,
    Variables: []
}
export default function Device({device}) {
    const theme = useTheme();
    const [information, setInformation] = React.useState(initInfo);
    const [chart, setChart] = React.useState([]);

    React.useEffect(()=>{
        (async ()=>{
            try{
                if(device.IsApproved==1){
                    const info = await GET(`/devices/${device.Id}`);
                    console.log(info.Variables);
                    const {Variables} = info;
                    var VarId=-1;
                    for(let i=0; i<Variables.length; i++){
                        if(Variables[i].Name=="SensorCurrent"){
                            VarId = Variables[i].Id;
                        }
                    }
                    if(VarId != -1){
                        var d = new Date();
                        const max = d.getTime();
                        //d.setDate(d.getDate() - 1);
                        d.setHours(d.getHours() - 24);
                        const min = d.getTime();
                        var trend = await GET(`/trends/${VarId}/${min}/${max}/5`);
                        for(var i=0; i<trend.length; i++){
                            trend[i].time = new Date(trend[i].From).toLocaleTimeString();
                            delete trend[i].From;
                            delete trend[i].To;
                        }
                        setChart(trend);
                        setInformation(info);
                    }
                }    
            }
            catch(e){
                console.error(e);
            }
        })();  
    },[]);


    function getVar(varName){
        for(var i=0; i<information.Variables.length; i++){
            if(varName==information.Variables[i].Name){
                if(!isNaN(Number(information.Variables[i].Real))){
                    return information.Variables[i].Real;
                }
                if(!isNaN(Number(information.Variables[i].Integer))){
                    return information.Variables[i].Integer;
                }
            }
        }
        return 0;
    }

    function niceDateTime(dt){
        const d = new Date(dt);
        return d.toLocaleString();
    }
    function pad(n){
        return n < 10?"0"+n.toFixed(0):n.toFixed(0);
    }
    function duration(d){
        d = d/1000;
        const dd = Math.floor(d/86400);
        const hh = Math.floor((d%86400)/3600);
        const mm = Math.floor((d%3600)/60);
        const ss = Math.floor((d%60));
        return `${dd} day(s) - ${pad(hh)}:${pad(mm)}:${pad(ss)}`;
    }
    if(device.IsApproved==0){
        return (<Grid item xs={12} md={4} lg={3}>
            <Paper
            sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 400,
            }}
            >
                <Typography variant="h5" align="center">{device.MAC}</Typography>
                <Alert severity="error">Device is connected but not approved for recording</Alert>
            </Paper>
        </Grid>);
    }

    return (
    <Grid item xs={12} md={4} lg={3}>
        
        <Paper
            sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 400,
            }}
        >
        <Typography variant="h5" align="center">{device.MAC}</Typography>
        <span><small>Cycle Counter: </small>{information.CycleCounter}</span>
        <span><small>On Time: </small>{duration(information.OnTime)}</span>

        <span><small>Sensor Current: </small>{ getVar("SensorCurrent").toFixed(4)}A</span>
        <span><small>Sensor Voltage: </small>{getVar("SensorVoltage").toFixed(4)}</span>
        <span><small>Sensor VPP: </small>{getVar("SensorVPP")}</span>
        <span><small>PP Raw: </small>{getVar("PPRaw")}</span>



        <span><small>Alive Since: </small>{niceDateTime(information.FirstAccessed)}</span>
        <span><small>Last Updated: </small>{niceDateTime(information.LastAccessed)}</span>
        <div style={{ width: '100%', flexGrow: 1, overflow: 'hidden' }}>  

            <LineChart
            dataset={chart}
            margin={{
                top: 16,
                right: 20,
                left: 70,
                bottom: 35,
            }}
            xAxis={[
                {
                scaleType: 'point',
                dataKey: 'time',
                tickLabelStyle: theme.typography.body2,    
                tickInterval: (v,i)=>{
                     return i%5==0;
                     },
                },
            ]}
            yAxis={[
                {
                label: 'Current (A)',
                labelStyle: {
                    ...theme.typography.body1,
                    fill: theme.palette.text.primary,
                },
                tickLabelStyle: theme.typography.body2,
                tickNumber: 3,
                },
            ]}
            series={[
                {
                connectNulls: true,
                dataKey: 'value',
                showMark: false,
                color: theme.palette.primary.light,
                },
            ]}
            sx={{
                [`.${axisClasses.root} line`]: { stroke: theme.palette.text.secondary },
                [`.${axisClasses.root} text`]: { fill: theme.palette.text.secondary },
                [`& .${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translateX(-25px)',
                },
            }}
            />
        </div>
        </Paper>
    </Grid>
    );
}