export function GET(url){
    return Request(url, "GET", null, false);
}


function Request(url,method,body=null,upload=false){
    var DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    const DEV_SERVER = "https://was.alexbaeck.com";
    const SERVER = "";
    return new Promise(function(resolve,reject){
      var opts;
      if(!upload){
        opts = {
          method: method,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-auth-token': localStorage.getItem('token'),
          }
        };
      }
      else{
        opts = {
          method: method,
          headers: {
            'Accept': 'application/json',
            'x-auth-token': localStorage.getItem('token')
          }
        };
      }
      if(body!==null){
        opts.body=body;
      }
      let status = 0;
        fetch(`${DEV?DEV_SERVER:SERVER}/api${url}`,opts).then((response)=>{
        if(response.status!==200 && response.status!==404 && response.status!==502){

        }
        if(response.status===401){
          throw response.status;
        }
        if(response.status===502){
        }
        if(response.status===200){
        }
        status = response.status;
        return response.json();
      }).then((data) => {
        //t
        setTimeout(function(){
          data.status = status;
          resolve(data);
        }, 10);
        
      },(status)=>{
        reject(status);
      }).catch(function(e) {
        console.log(e);
        console.log("error");
      });
    });
  }